.navbar-static-top {
    margin-bottom:20px;
  }
  
  i {
    font-size:16px;
  }
  
  .nav > li > a {
    color:#787878;
  }
    
  footer {
    margin-top:20px;
    padding-top:20px;
    padding-bottom:20px;
    background-color:#efefef;
  }
  
  /* count indicator near icons */
  .nav>li .count {
    position: absolute;
    bottom: 12px;
    right: 6px;
    font-size: 10px;
    font-weight: normal;
    background: rgba(51,200,51,0.55);
    color: rgba(255,255,255,0.9);
    line-height: 1em;
    padding: 2px 4px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
  }
  
  /* indent 2nd level */
  .list-unstyled li > ul > li {
     margin-left:10px;
     padding:8px;
  }
  .buttonD {
    margin-top: 0px;
    position:absolute;
    box-shadow: 0 0 15px #055160;
    background-color: #1f242a;
    top:0;
    right:0;
}
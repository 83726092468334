img{
	max-width: 70%;
}
.gallery{
	background-color: #dbddf100;
	padding: 10px 0;
}
.gallery img{
	background-color: #1f242a;
    padding: 40px;
    width: 100%;
    box-shadow: 0 0 15px #055160;
    cursor: pointer;
}
#gallery-modal .modal-img{
	height: 60vh;
	background-color: #1f242a;
    box-shadow: 0 0 15px #055160;
}


.modal-content{
	background-color: #1f242a!important;
    box-shadow: 0 0 15px #055160;
}
.modal-header {
    border-bottom: 1px solid #1f242a!important;
}
.btn-close {
	background-color: #fdfdfd!important;
}